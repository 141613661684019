<template>
    <div class="loginDiv" id="signInDiv" style="gap: 20px;" v-if="type == 1">
        <div class="flexRow centered" style="width: 40%; background-color: #eeeeee;">
            <div class="flexColumn" style="background-color: white; width: 50%; border-radius: 20px 0px 0px 20px;">
                <img style="width: 10%; margin-top: 1vmax; margin-left: 1vmax;" src="../../assets/img/VaultekSquareLogo.png">
                <div class="flexColumn centered" style="margin-top: 30px; gap: 15px;">
                    <p style=" text-align:center;color: black; font: 15px;">
                        Vaultek ViSN™ Account Login
                    </p>
                    <label style="text-align: left;" class="gray11Text">Email</label>
                    <input 
                    v-model="loginFrom.username"
                    style="height: 3vh; width: 90%; background-color: white; border: none; border-bottom: 1px solid lightgray;" type="email">
                    <label class="gray11Text">Password</label>
                    <input
                     v-model="loginFrom.password"
                     style="height: 3vh; width: 90%; border: none; border-bottom: 1px solid lightgray;" type="password">
                    <button class="largeBlackloginButton" id="loginBtn" @click="loginM">
                        Login
                    </button>
                    <p class="gray11Text centered">Don't have an account?
                        <button id="signUpHereBtn" style="background-color: white; border:none; cursor: pointer;" @click="type = 2">Sign Up</button>
                    </p>
                </div>
                <button class="gray11Text" style="background-color: white; border:none; cursor: pointer; margin-top: 2vmax; margin-left: 12vmax; padding-bottom: 10px;" 
                @click="type = 3">Forget Password?</button>
            </div>
            <div class="flexColumn" style="background-color: cornflowerblue; width: 50%; border-radius: 0px 20px 20px 0px;">
                <img src="../../assets/img/ViSN.png" style="width: 100%; height: 100%; border-radius: 0px 20px 20px 0px;">
            </div>
        </div>
    </div>

    <div class="loginDiv" id="signUpDiv" style="gap: 20px;" v-if="type == 2">
        <div class="flexRow centered" style="width: 40%; background-color: #eeeeee;">
            <div class="flexColumn" style="background-color: white; width: 50%; border-radius: 20px 0px 0px 20px;">
                <img style="width: 10%; margin-top: 1vmax; margin-left: 1vmax;" src="../../assets/img/VaultekSquareLogo.png">
                <div class="flexColumn centered" style="margin-top: 30px; gap: 15px;">
                    <p style="color: black; font: 15px;">Create a Vaultek ViSN&trade; Account</p>
                    <label style="text-align: left;" class="gray11Text">Email</label>
                    <input 
                    v-model="loginFrom.username"
                    style="height: 3vh; width: 90%; background-color: white; border: none; 
                    border-bottom: 1px solid lightgray;" type="email">
                    <label class="gray11Text">Password</label>
                    <input 
                     v-model="loginFrom.password"
                    style="height: 3vh; width: 90%; border: none; border-bottom: 1px solid lightgray;" type="password">
                    <button class="largeBlackloginButton" id="signUpBtn" @click="goSignUp">Sign Up</button>
                    <p class="gray11Text centered" style="padding-bottom: 3.5vmax;">Already have an account?
                        <button id="loginHereBtn" style="background-color: white; border:none; cursor: pointer;" @click="type = 1">Log in here</button></p>
                </div>
            </div>
            <div class="flexColumn" style="width: 50%; border-radius: 0px 20px 20px 0px;">
                <img src="../../assets/img/ViSN.png" style="width: 100%; height: 100%; border-radius: 0px 20px 20px 0px;">
            </div>
        </div>
        <sendEmailDom ref="sendEmailDomRef" @sendVeriy="sendVeriy"></sendEmailDom>
    </div>

    <div class="loginDiv" id="resetPasswordDiv" style="gap: 20px;" v-if="type == 3">
        <div class="flexRow centered" style="width: 40%; background-color: #eeeeee;">
            <div class="flexColumn" style="background-color: white; width: 50%; border-radius: 20px 0px 0px 20px;">
                <img style="width: 10%; margin-top: 1vmax; margin-left: 1vmax;" src="../../assets/img/VaultekSquareLogo.png">
                <div class="flexColumn centered" style="margin-top: 30px;">
                    <span style="text-align:center;color: black; font-size: 15px; margin-top: 2vh;">Reset Your Account Password</span>
                    <label style="text-align: left; margin-top: 3vh;" class="gray11Text">Email</label>
                    <input class="centered" 
                    v-model="restloginFrom.username"
                    style="margin-top: 2vh; height: 3vh; width: 15vmax; background-color: white; border: none; border-bottom: 1px solid lightgray;" 
                    type="email">
                    <label style="margin-top: 2vh;" class="gray11Text">Password</label>
                    <input class="centered" 
                    v-model="restloginFrom.password"
                    style="margin-top: 2vh; height: 3vh; width: 15vmax; border: none; border-bottom: 1px solid lightgray;" type="password">
                    <label style="margin-top: 2vh;" class="gray11Text">ConfirmPassword</label>
                    <input class="centered" 
                    v-model="restloginFrom.password1"
                    style="margin-top: 2vh; height: 3vh; width: 15vmax; border: none; border-bottom: 1px solid lightgray;" type="password">
                    <button class="centered" style="border:none; cursor: pointer; margin-top: 4vh; margin-bottom: 4vh; height: 5vh; text-align: center; width:15vmax; background-color: black; border-radius: 10px; color: lightgray;" 
                    id="resetPasswordBtn" @click="sendEmail1">
                        Reset Password</button>
                </div>
            </div>
            <div class="flexColumn" style="background-color: cornflowerblue; width: 50%; border-radius: 0px 20px 20px 0px;">
                <img src="../../assets/img/ViSN.png" style="width: 100%; height: 100%; border-radius: 0px 20px 20px 0px;">
            </div>
        </div>
    </div>

    <div class="loginDiv" id="verificationDiv" style="gap: 20px;" v-if="type == 4">
        <div class="flexRow centered" style="width: 40%; background-color: #eeeeee;">
            <div class="flexColumn" style="background-color: white; width: 50%; border-radius: 20px 0px 0px 20px;">
                <img style="width: 10%; margin-top: 1vmax; margin-left: 1vmax;" src="../../assets/img/VaultekSquareLogo.png">
                <div class="flexColumn centered" style="margin-top: 30px;">
                    <span style="text-align:center;color: black; font-size: 15px; margin-top: 2vh;">Verification Code</span>
                    <span class="gray11Text" style="text-align:center; margin-top: 2vh;">A verification code has been sent to your email.</span>
                    <span class="gray11Text" style="text-align:center; margin-top: 1vh;">Enter the code below to reset your password.</span>
                    <input class="centered gray11Text" placeholder="Enter Verification Code" 
                    v-model="restloginFrom.code"
                    style="margin-top: 4vh; text-align: center; height: 4vh; width: 15vmax; background-color: white; border-radius: 10px; border: 1px solid lightgray;" type="text">
                    <button class="centered" style="margin-top: 4vh; height: 5vh; border:none; cursor: pointer; text-align: center; width:15vmax; background-color: black; border-radius: 10px; color: lightgray;"
                     id="verifyBtn" @click="resetPasswordM">Reset My Password</button>
                </div>
            </div>
            <div class="flexColumn" style="background-color: cornflowerblue; width: 50%; border-radius: 0px 20px 20px 0px;">
                <img src="../../assets/img/ViSN.png" style="width: 100%; height: 100%; border-radius: 0px 20px 20px 0px;">
            </div>

        </div>
    </div>

    <div class="loginDiv" id="resetPasswordCompleteDiv" style="gap: 20px;" v-if="type == 5">
        <div class="flexRow centered" style="width: 40%; background-color: #eeeeee;">
            <div class="flexColumn" style="background-color: white; width: 50%; border-radius: 20px 0px 0px 20px;">
                <img style="width: 10%; margin-top: 1vmax; margin-left: 1vmax;" src="../../assets/img/VaultekSquareLogo.png">
                <div class="flexColumn centered" style="margin-top: 30px;">
                    <span style="text-align:center;color: black; font-size: 15px; margin-top:8vh;">Password Reset Successfully</span>
                    <button class="centered" style="border:none; cursor: pointer; margin-top: 4vh; margin-bottom: 4vh; height: 5vh; text-align: center; width:13vmax; background-color: black; border-radius: 10px; color: lightgray;" 
                    id="goToLoginBtn" @click="type = 1">Log in</button>
                </div>
            </div>
            <div class="flexColumn" style="background-color: cornflowerblue; width: 50%; border-radius: 0px 20px 20px 0px;">
                <img src="../../assets/img/ViSN.png" style="width: 100%; height: 100%; border-radius: 0px 20px 20px 0px;">
            </div>
        </div>
    </div>

</template>

<script setup>
 import { ref } from 'vue'
 import { authLogin, getromoteTime, authRegister, resetPassword } from "@/api/login"  
 import { sendEmail } from "@/api/account" 
 import { useStore } from "vuex";
 import JSEncrypt from 'jsencrypt';
 import { LocalStore } from "@/utils/comm"
 import sendEmailDom from "@/page/common/sendEmail.vue"
import { ElMessage } from 'element-plus'
 const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; 
 const sendEmailDomRef = ref("")
 const store = useStore();

 const type = ref(1) // 1 Login 2注册

 const loginFrom = ref({
    username: "",
    password: ''
 })

 const restloginFrom = ref({
    username: "",
    password: "",
    password1: ""
 })

 let timestamp = ref(0) 

 const key = ref("")

 const passwordJm = (pass) => {
    // $t('Log.chuangJYG') JSEncrypt $t('Log.shiL')
    var encryptor = new JSEncrypt();
    // $t('Log.sheZGY')
    encryptor.setPublicKey(`MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAlsswh6vCfzyx25SpEZ3PCojY
    QswZbVggghUwnUt7vyM/TqaTWWvFUc3LNseZzEtiVlPFaouZz6c0wQ5/EeJuH3RS20jNUGX6YpyyQgzT0LAUfFFVCARAt5SSHxKJ0TgHzqIDEK1tI1at0EMSEnE6oEMzSUTCIzoYpnIn18ivjicQ70W3YM/BUkhZmllcEwF3OnLHTm28PVhPZC7urvq1+uWfPvcU3X9kezfphS9gyV60PF4vhUW0v9jK7m2D2zvPSSksyU1GSuSosG+GcfJgOnZ/a+V+V5JWop50xA4DtLG2CQl/NdLBtmq3ELnH8lTzj/ExFhj2878SefEHMBJlvQIDAQAB`);
    // $t('Log.jiaM')
    console.log(1222, encryptor.encrypt(pass))
    return encryptor.encrypt(pass);
}

 const loginM = async () => {
    if (!loginFrom.value.username) {
        return  ElMessage({
        message: `Username should not be empty`,
        type: 'warning',
        plain: true}) 
    }

    // 判断邮箱是否合适
    if (!emailPattern.test(loginFrom.value.username)) {
        return  ElMessage({
        message: `Invalid username`,
        type: 'warning',
        plain: true}) 
    }

    if (!loginFrom.value.password) {
        return  ElMessage({
        message: `password should not be empty`,
        type: 'warning',
        plain: true}) 
    }
        // 登录
    let params = {
        device: "Chrome",
        expires: 3600000,
        language: "enUS",
        loginType: "Web",
        remember: false,
        verifyCode: "",
        verifyKey: "",
        ...loginFrom.value
    }
    const fwTime = await getromoteTime() || {};
    const timestamp = fwTime.result;
    params.password = passwordJm(`${loginFrom.value.password}|${timestamp}`)
    authLogin(params).then(res => {
        store.commit("setUserInfos", {
            ...res.result,
            token: res.result.token,
            isAdmin: res.username === "admin"
        })
        LocalStore.set("X-Access-Token", res.result.token)
        setTimeout(() => {
            store.commit("setIsLogin", true)
        }, 0)
    }).catch(e => {
        console.log(e)
    })
 }

 const goSignUp = () => {
    // 注册
    // {"method":"email","requireAccountExists":false,"sendTo":"11@qq.com"}
    if (!loginFrom.value.username) {
        return  ElMessage({
        message: `Username should not be empty`,
        type: 'warning',
        plain: true}) 
    }
    let params = {
        "method":"email",
        "requireAccountExists":false,
        "sendTo": loginFrom.value.username
    }
    sendEmail(params).then(res => {
        key.value = res.result.key
        sendEmailDomRef.value.open({}, 2)
    })
 }

 const sendEmail1 = () => {
    // 先判断必填 然后判断密码
    console.log("restloginFrom.value", restloginFrom.value)
    if (!restloginFrom.value.username) {
        return  ElMessage({
        message: `Username should not be empty`,
        type: 'warning',
        plain: true}) 
    }

    
    // 判断邮箱是否合适
    if (!emailPattern.test(restloginFrom.value.username)) {
        return  ElMessage({
        message: `Invalid username`,
        type: 'warning',
        plain: true}) 
    }

    if (!restloginFrom.value.password) {
        return  ElMessage({
        message: `password should not be empty`,
        type: 'warning',
        plain: true}) 
    }


    if (restloginFrom.value.password !== restloginFrom.value.password1) {
        return  ElMessage({
        message: `password ConfirmPassword is inconsistent`,
        type: 'warning',
        plain: true}) 
    }

    // 判断密码需要是6到12为
    // The password must include 6-12 characters,including numbers,uppercase letters,lowercase letters
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,12}$/;
    if (!regex.test(restloginFrom.value.password)) {
        return ElMessage({
        message: `The password must include 6-12 characters,including numbers,uppercase letters,lowercase letters`,
        type: 'warning',
        plain: true}) 
    } 

    let params = {
        "method":"email",
        "requireAccountExists":false,
        "sendTo": restloginFrom.value.username
    }
    sendEmail(params).then(res => {
        key.value = res.result.key
        timestamp.value = res.result.timestamp
        type.value = 4
    })
 }

 const sendVeriy = async (code) => {
    const fwTime = await getromoteTime() || {};
    const timestamp = fwTime.result;
    let datas = {
        "username": loginFrom.value.username, //用户名
        "key": key.value, //验证码KEY
        "password": "", //加密后的密码
        "captcha": code, //邮件收到的验证码
    }
    datas.password = passwordJm(`${loginFrom.value.password}|${timestamp}`)
    authRegister(datas).then(_ => {
        ElMessage({
        message: `Sign up success`,
        type: 'success',
        plain: true}) 
        type.value = 1
    })
 }

 const resetPasswordM = async () => {
    let password = passwordJm(`${restloginFrom.value.password}|${timestamp.value}`)
    // 忘记密码
    let data = {
        captcha: restloginFrom.value.code,
        key: key.value,
        password: password,
        register: false,
        username: restloginFrom.value.username
    }
    resetPassword(data).then(res => {
        ElMessage({
        message: `Sign up success`,
        type: 'success',
        plain: true}) 
        type.value = 5
    })
 }

</script>

<style lang="scss" scoped>

</style>