<template>
    <el-dialog class="sendEmail" 
    v-model="dialogTableVisible"
    :append-to-body="true"
    title="Shipping address" width="400">
        <template #header>
            <div class="header1">
                Enter Verification Code
            </div>
      </template>
      <div>
        <div class="tip">
            A verification code has been sent to your accoun's email address
        </div>
        <div>
            <el-input v-model="code" 
                    class="code"
                    placeholder="Enter Verification Code" />
        </div>
        <div class="footer">
            <div @click="sendVeriy">OK</div>
            <div @click="dialogTableVisible = false">Cancel</div>
        </div>
      </div>
    </el-dialog>
</template>


<script setup lang="js">
import { ref, defineExpose, defineEmits } from "vue"
import { modifyPassword } from "@/api/account"
import { ElMessage } from 'element-plus'
import JSEncrypt from 'jsencrypt';
const emit = defineEmits(['sendVeriy']);
const dialogTableVisible = ref(false)
const key = ref("")
const code = ref("")
const newPassword = ref("")
const oldPassword = ref("")
const timestamp = ref("")
const type = ref(1)

const open = (row, num = 1) => {
    dialogTableVisible.value = true
    type.value = num
    if (type.value == 1) {
        key.value = row.key
        newPassword.value = row.newPassword
        oldPassword.value = row.oldPassword
        timestamp.value = row.timestamp
    }
}

const close = () => {
    dialogTableVisible.value = false
}

const passwordJm = (pass) => {
    // $t('Log.chuangJYG') JSEncrypt $t('Log.shiL')
    var encryptor = new JSEncrypt();
    // $t('Log.sheZGY')
    encryptor.setPublicKey(`MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAlsswh6vCfzyx25SpEZ3PCojY
    QswZbVggghUwnUt7vyM/TqaTWWvFUc3LNseZzEtiVlPFaouZz6c0wQ5/EeJuH3RS20jNUGX6YpyyQgzT0LAUfFFVCARAt5SSHxKJ0TgHzqIDEK1tI1at0EMSEnE6oEMzSUTCIzoYpnIn18ivjicQ70W3YM/BUkhZmllcEwF3OnLHTm28PVhPZC7urvq1+uWfPvcU3X9kezfphS9gyV60PF4vhUW0v9jK7m2D2zvPSSksyU1GSuSosG+GcfJgOnZ/a+V+V5JWop50xA4DtLG2CQl/NdLBtmq3ELnH8lTzj/ExFhj2878SefEHMBJlvQIDAQAB`);
    // $t('Log.jiaM')
    console.log(1222, encryptor.encrypt(pass))
    return encryptor.encrypt(pass);
}

const sendVeriy = () => {
    if (type.value != 1) {
        emit("sendVeriy", code.value)
        return
    }
    let data = {
        captcha: code.value,
        key: key.value,
        newPassword: passwordJm(`${newPassword.value}|${timestamp.value}`),
        oldPassword: passwordJm(`${oldPassword.value}|${timestamp.value}`)
    }
    modifyPassword(data).then(() => {
        ElMessage({
            message: "modifyPassword success",
            type: 'success',
        plain: true})
    })
}

defineExpose({
    open,
    close
})
</script>

<style lang="scss" scoped>
.header1 {
    font-size: 18px;
    text-align: center;
    color: #000;
}
.tip {
    font-size: 12px;
    margin-bottom: 20px;
}
.footer {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    &>div {
        height: 45px;
        width: 160px;
        font-size: 14px;
        border-radius: 8px;
        color: lightgray;
        line-height: 45px;
        background-color: #000;
        text-align: center;
        cursor: pointer;
    }
}
</style>

<style>
.el-dialog {
    background-color: #fff;
}

</style>