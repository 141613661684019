import server from '@/utils/http'

/**
 * 获取验证码配置
 * @returns 
 */
export const config = () => server.get(`/authorize/captcha/config`)

/**
 * 获取验证码图片
 * @returns 
 */
export const code = () => server.get(`/authorize/captcha/image?width=130&height=30`)

/**
 * 登录
 * @returns 
 */
export const authLogin = (data) => server.post(`/app/user/login`, data)

// 注册用户 app/user/register
export const authRegister= (data) => server.post(`/app/user/register`, data)

/**
 * 查询初始化配置信息
 * @returns 
 */
export const getInitSet = () => server.get(`/user/settings/init`)

/**
 * 创建初始化配置信息
 * @returns 
 */
export const postInitSet = (data) => server.post(`/user/settings/init`, data)

/**
 * 查询系统版本信息
 * @returns 
 */
export const systemVersion = () => server.get(`/system/version`)

/**
 * 获取支持的SSO的应用
 * @returns 
 */
export const bindInfo = () => server.get(`/application/sso/_all`)

/**
 * 查询配置信息
 * @returns 
 */
export const settingDetail = (scopes) => server.get(`/system/config/${scopes}`)

/**
 * 获取当前登录用户信息
 */
export const userDetail = () => server.get('/user/detail')

/**
 * 退出登录
 */
export const loginout_api = () => server.get('/user-token/reset')

export const getOAuth2 = (params) => server.get('/oauth2/authorize', params)

export const initApplication = (clientId) => server.get(`/application/${clientId}/info`)


// 获取服务器时间

export const getromoteTime = () => server.get('/app/user/timestamp')

// 忘记密码
export const resetPassword = (data) => server.post(`/app/user/resetPassword`, data)