import server from '@/utils/http'

/**
 * 修改用户
 * @returns 
 */
export const updateUser = (data) => server.post(`/app/user/userDetail`, data)

// 发送邮件
export const sendEmail = (data) => server.post(`/app/user/sendCaptcha`, data)

// 验证邮箱
export const modifyPassword = (data) => server.post(`/app/user/modifyPassword`, data)

// sublog 
// orderBy:"createTime desc"
// pageIndex:0
// pageSize:20
export const orderMyPage = (data) => server.post(`/vip/order/myPage`, data)
// 登录日志 pageIndex=0&pageSize=20
export const getUserLog = (params) => server.get(`/app/user/log`, params)

// /listFpTemplate pageIndex=0&pageSize=15
export const getListFpTemplate = (params) => server.get(`/app/device/listFpTemplate`, params)

// 删除指纹 device/removeFpTemplate/:id
export const removeFpTemplate = (id) => server.post(`/app/device/removeFpTemplate/${id}`)

// 修改指纹名称
export const updateFpName = (id, name) => server.post(`/app/device/updateFp/${id}/${name}`)

// 注销用户
export const unregisterAccount = (params) => server.remove(`/app/user/unregisterAccount`, params)
