<template>
  <!-- <router-view/> -->
   <div style="height: 100%;">
      <Login v-if="!isLogin"></Login>
      <Home v-else></Home>
   </div>
</template>

<script setup>
import Home from "./page/home/index.vue"
import Login from "./page/login/index.vue"
import { ref, computed } from "vue"
import { useStore } from "vuex";


const store = useStore();

const isLogin = computed(() => {
  return store.state.isLogin
})
</script>

<style>
#app {
  color: #fff;
  height: 100%;
  width: 100%;
}


</style>
