<template>
    <div class="header">
        <div class="headerLogoDiv">
            <img src="../../assets/img/VaultekIcon.png" class="headerIcon">
            <img src="../../assets/img/ViSNbanner.png" class="headerLogo">
        </div>
        <div class="headerNavDiv">
            <button id="homeBtn" @click="gohome"><img src="../../assets/img/homeIcon.png" style="height: 20px"></button>
            <button id="accountSettingsBtn" @click="goAccount">Account</button>
            <button id="logoutBtn" @click="goLogout">Logout</button>
            <button id="storeBtn" @click="goStore">Store</button>
        </div>
    </div>
</template>

<script setup>
 import { ref } from 'vue'
 import { useRouter } from 'vue-router'
 import { cleanToken } from '@/utils/comm'
 import { useStore } from "vuex";
 const store = useStore();

const router = useRouter()

 const goStore = () => {
    window.open("https://www.vaulteksafe.com/")
 }

 const goAccount = () => {
    router.push({
        path: "/account"
    })
 }

 const gohome = () => {
    router.replace({
        path: "/"
    })
 }

 const goLogout = () => {
    cleanToken()
    store.commit("setIsLogin", false)
    router.replace({
        path: "/"
    })
 }
</script>


<style lang="scss" scoped>

.headerNavDiv {
    display: flex;
    align-items: center;
    height: 100%;
}

</style>