import server from '@/utils/http'

// 设备列表
export const getListDevices = (params) => server.get(`/app/product/device/listDevices`, params)

// 设备日志
export const getDeviceLog = (data) => server.post(`/app/device/logs/web`, data)

// 日志导出
export const exportDeviceLog = (params) => server.getStream1(`/app/device/logs/export`, params)

// 清除日志
export const deviceClearLog = (params) => server.get(`/app/device/clearLog`, params)

// 告警日志
export const getAlarmLog = (params) => server.get(`/app/device/alarm`, params)
 
// 获取指纹列表userGroup=FP
export const getListFpTemplate = (params) => server.get(`/app/device/getUserList`, params)


// nano key  /app/device/write/properties {"deviceId":"94C960D82F11","properties":{"LockSettings":{"DualEntryMode":2}}}

// disable /api/app/device/write/properties {"deviceId":"94C960D82F11","properties":{"LockSettings":{"PINCodeLock":1}}}

// 设置属性值
export const setProperties = (data) => server.post(`/app/device/write/properties`, data)

// text Notifications
export const getNotifySettings = (params) => server.get(`/app/user/notifySettings`, params)

// 获取设备分享列表
export const getShareList = (deviceId) => server.get(`/app/product/device/getShareList/${deviceId}`)

// 取消分享
export const removeShareList = (deviceId) => server.get(`/app/product/device/stopShare/${deviceId}`)

// 清空日志的时候先发邮件 app/user/sendCaptcha  {"method":"email","requireAccountExists":false,"sendTo":"caojun@waresforce.com"}
export const sendCaptcha = (data) => server.post(`/app/user/sendCaptcha`, data)

// 获取临时密码
export const getTemporaryPassword = (deviceId) => server.get(`/app/device/getTemporaryPassword?deviceId=${deviceId}`)

// 获取产品信息接口
export const getProductInfo = (deviceId) => server.get(`app/product/device/productInfo/${deviceId}`)