import { createRouter, createWebHashHistory } from 'vue-router'
// import HomeView from '../page/home/index.vue'

const routes = [
  {
    path: '/',
    name: 'device',
    component: () => import(/* webpackChunkName: "device" */ '../page/right/device/index.vue')
  },
  {
    path: '/log',
    name: 'log',
    component: () => import(/* webpackChunkName: "log" */ '../page/right/log/index.vue')
  },
  {
    path: '/accessPoints',
    name: 'accessPoints',
    component: () => import(/* webpackChunkName: "accessPoints" */ '../page/right/accessPoints/index.vue')
  },
  {
    path: '/fingerprint',
    name: 'fingerprint',
    component: () => import(/* webpackChunkName: "accessPoints" */ '../page/right/accessPoints/common/fingerprint.vue')
  },
  {
    path: '/masterCode',
    name: 'masterCode',
    component: () => import(/* webpackChunkName: "accessPoints" */ '../page/right/accessPoints/common/masterCode.vue')
  },
  {
    path: '/nanoKey',
    name: 'nanoKey',
    component: () => import(/* webpackChunkName: "accessPoints" */ '../page/right/accessPoints/common/nanoKey.vue')
  },
  {
    path: '/dualMode',
    name: 'dualMode',
    component: () => import(/* webpackChunkName: "accessPoints" */ '../page/right/accessPoints/common/dualMode.vue')
  },
  {
    path: '/disableAccess',
    name: 'disableAccess',
    component: () => import(/* webpackChunkName: "accessPoints" */ '../page/right/accessPoints/common/disableAccess.vue')
  },
  {
    path: '/temporaryPasscodes',
    name: 'temporaryPasscodes',
    component: () => import(/* webpackChunkName: "accessPoints" */ '../page/right/accessPoints/common/temporaryPasscodes.vue')
  },
  {
    path: '/alarms',
    name: 'alarms',
    component: () => import(/* webpackChunkName: "accessPoints" */ '../page/right/alarms/index.vue')
  },
  {
    path: '/setting',
    name: 'setting',
    component: () => import(/* webpackChunkName: "accessPoints" */ '../page/right/setting/index.vue')
  },
  {
    path: '/shareSafe',
    name: 'shareSafe',
    component: () => import(/* webpackChunkName: "accessPoints" */ '../page/right/shareSafe/index.vue')
  },
  {
    path: '/alertPreferences',
    name: 'alertPreferences',
    component: () => import(/* webpackChunkName: "alertPreferences" */ '../page/right/alertPreferences/index.vue')
  },
  {
    path: '/alertTip',
    name: 'alertTip',
    component: () => import(/* webpackChunkName: "alertPreferences" */ '../page/right/alertPreferences/common/alertTip.vue')
  },
  {
    path: '/textNotifications',
    name: 'textNotifications',
    component: () => import(/* webpackChunkName: "alertPreferences" */ '../page/right/alertPreferences/common/textNotifications.vue')
  },
  {
    path: '/emergencySos',
    name: 'emergencySos',
    component: () => import(/* webpackChunkName: "alertPreferences" */ '../page/right/alertPreferences/common/emergencySos.vue')
  },
  {
    path: '/emailNotifications',
    name: 'emailNotifications',
    component: () => import(/* webpackChunkName: "alertPreferences" */ '../page/right/alertPreferences/common/emailNotifications.vue')
  },
  {
    path: '/descrit',
    name: 'descrit',
    component: () => import(/* webpackChunkName: "alertPreferences" */ '../page/right/alertPreferences/common/descrit.vue')
  },
  {
    path: '/account',
    name: 'account',
    component: () => import(/* webpackChunkName: "account" */ '../page/account/index.vue')
  },
  {
    path: '/editUser',
    name: 'editUser',
    component: () => import(/* webpackChunkName: "editUser" */ '../page/account/common/editUser.vue')
  },
  {
    path: '/updatePassword',
    name: 'updatePassword',
    component: () => import(/* webpackChunkName: "updatePassword" */ '../page/account/common/updatePassword.vue')
  },
  {
    path: '/subscriptionHistory',
    name: 'subscriptionHistory',
    component: () => import(/* webpackChunkName: "subscriptionHistory" */ '../page/account/common/subscriptionHistory.vue')
  },
  {
    path: '/loginLog',
    name: 'loginLog',
    component: () => import(/* webpackChunkName: "loginLog" */ '../page/account/common/loginLog.vue')
  },
  {
    path: '/accountFingerprint',
    name: 'accountFingerprint',
    component: () => import(/* webpackChunkName: "accountFingerprint" */ '../page/account/common/fingerprint.vue')
  },
  {
    path: '/userRigis',
    name: 'userRigis',
    component: () => import(/* webpackChunkName: "loginLog" */ '../page/account/common/userRigis.vue')
  },
  {
    path: '/privacyPolicy',
    name: 'privacyPolicy',
    component: () => import(/* webpackChunkName: "loginLog" */ '../page/account/common/privacyPolicy.vue')
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import(/* webpackChunkName: "loginLog" */ '../page/account/common/aboutUs.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(), // createWebHistory(process.env.BASE_URL)
  routes
})

export default router
