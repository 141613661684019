import md5 from 'js-md5';

export const TOKEN_KEY = 'X-Access-Token'

/**
 * 静态图片资源处理
 * @param path {String} 路径
 */
export const getImage = (path) => {
  return new URL('/images' + path, import.meta.url).href
}

export const LocalStore = {
  set(key, data) {
    localStorage.setItem(key, typeof data === 'string' ? data : JSON.stringify(data))
  },
  get(key) {
    const dataStr = localStorage.getItem(key)
    try {
      if (dataStr) {
        const data = JSON.parse(dataStr)
        return data && typeof data === 'object' ? data : dataStr
      } else {
        return dataStr
      }
    } catch (e) {
      return dataStr
    }
  },
  remove(key) {
    localStorage.removeItem(key)
  },
  removeAll() {
    localStorage.clear()
  }
}

export const getToken = () => {
  return LocalStore.get(TOKEN_KEY)
}

export const cleanToken = () => {
  LocalStore.remove(TOKEN_KEY)
}


export const md5Js = (str) => {
  if (str === '') {
    return ''
  }
  return md5(str);
}


export const timeSwicth = (time) => {
  // Aug 09, 2024 4:24 PM
  if (time) {
      let timestamp = time
      const date = new Date(timestamp);  
      const options = {  
          year: 'numeric',  
          month: 'short',  
          day: 'numeric',  
          hour: 'numeric',  
          minute: 'numeric',  
          hour12: true,
          timeZone: 'GMT' // 设置为 GMT 的时区   
      };  
      return date.toLocaleString('en-US', options);   
  } else {
      return ""
  }
}